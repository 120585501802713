<template>
    <div class="auditSubmitBar audit-footer-outer audit-submit-bar"
        v-if="auditSelectData.length > 1"
         :style="auditSubmitBarStyle"
    >
        <div class="copy-left">
            已选{{auditSelectData.length}}项
        </div>
        <div class="copy-right">
            <el-button size="small" @click="submitEditCopyHandle" type="danger">审核</el-button>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'auditSubmitBar',
    props: {
      auditSelectData: {
        type: Array,
        default(){
          return [];
        }
      }
    },
    computed: {
      ...mapGetters(['sidebar']),
      auditSubmitBarStyle(){
        if(this.sidebar.opened){
          return {
            paddingLeft: '250px'
          }
        }else {
          return {
            paddingLeft: '50px'
          }
        }
      }
    },
    methods: {
      submitEditCopyHandle() {
        this.$emit('submit');
      }
    }
  };
</script>

<style lang="less" scoped>
    .auditSubmitBar {
        position: fixed;
        height: 50px;
        line-height: 50px;
        background-color: #ffffff;
        width: 100%;
        bottom: 0;
        left: 0;
        font-size: 14px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        .copy-left {
            height: 100%;
            .link {
                margin-right: 20px;
            }
        }
        .copy-right {
            margin-right: 20px;
        }
    }
    .audit-footer-outer {
        padding-right: 0;
    }
    .audit-submit-bar {
        z-index: 99;
    }
</style>
