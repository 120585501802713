import { initData } from '@/api/data'

export default {
  data() {
    return {
      multipleSelection: [],
      batchDialogShow: false
    }
  },
  methods: {
    selectCheck(){
      // tableStatus[1,2,3,4,5] 为客户部审核,清算部审核,交易部审核,清算部复核,财务部出金,
      return [1,2,3,4,5].includes(this.tableStatus);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submit(){
      this.batchDialogShow = true;
    },
    closeHandle(){
      this.batchDialogShow = false;
    },
    getIds(){
      let ids = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id);
      }
      return ids;
    },
    getAuditBy(){
      return this.tableStatus;
    }
  }
}
