<template>
  <div>
    <el-button
      v-if="isCheck"
      size="mini"
      type="danger"
      @click="to"
    >{{'审核'}}</el-button>
    <el-button
      v-if="checkBtnShow"
      size="mini"
      type="success"
      @click="to"
    >{{'查看'}}</el-button>
    <detail
      ref="detail"
      :sup_this="sup_this"
      :isCheck="isCheck"
      :data="data"
      :tableStatus="tableStatus"
    />
  </div>
</template>
<script>
import checkPermission from '@/utils/permission'
import detail from './detail'
import { initData } from '@/api/data'

export default {
  components: { detail },
  props: {
    isCheck: {
      type: Boolean,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  computed: {
    checkBtnShow() {
      return !this.isCheck &&  checkPermission(['ADMIN','COMMISSION_INFO_ALL','COMMISSION_APPLY_INFO','COMMISSION_CUST_INFO','COMMISSION_AUDIT_INFO'])
    }
  },
  methods: {
    to() {
      const _this = this.$refs.detail
      var url = '/crm/tCommissionEvent'
      const params = { commissionId: this.data.id}
      initData(url,params).then(res=>{
        _this.events=res.content
        _this.dialog = true
      }).catch(err => {
        console.log(err.response.data.message)
      })

    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
