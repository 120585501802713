<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.type"
            clearable
            placeholder="出佣类型"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in commiTypeOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-select
            @change="toQuery"
            v-if="tableStatus=='-1'"
            v-model="query.chkStatus"
            clearable
            placeholder="审核状态"
            class="filter-item"
            style="width: 130px">
      <el-option
              v-for="item in commiStatusOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-input
      v-model="query.custInfo"
      clearable
      placeholder="客户姓名/ID"
      style="width: 150px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.managerInfo"
      clearable
      placeholder="客户经理姓名/ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
     <div class="filter-item">
      申请时间：
      <el-date-picker
        v-model="query.startTime"
        type="datetime"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        v-model="query.endTime"
        type="datetime"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="filter-item">
      审核通过时间：
      <el-date-picker
        v-model="query.startAuditTime"
        type="datetime"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        v-model="query.endAuditTime"
        type="datetime"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-button
            v-if="showExport"
            class="filter-item"
            size="mini"
            type="primary"
            @click="exportHandle"
            :loading="loading"
    >导出</el-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    },
    showExport:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'ibTypeOptions',
      'commiTypeOptions',
      'currencyTypeOptions',
      'commiStatusOptions'
    ])
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear');
    },
    exportHandle(){
      this.$emit('export');
    }
  }
}
</script>
