<template>
    <el-dialog
        :visible="dialogShow"
        :before-close="closeHandle"
        title="批量审核"
        append-to-body
        width="600px"
    >
        <money-audit
                ref="moneyaudit"
                @send-reason="sendReason"
        ></money-audit>
        <div slot="footer">
            <el-button type="text" @click="cancelHandle">取消</el-button>
            <el-button type="primary" size="small" @click="confirmHandle">确认</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import moneyAudit from './moneyAudit'
  export default {
    name: 'batchReviewDialog',
    components: {
      moneyAudit,
    },
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      closeHandle() {
        this.$emit('close');
      },
      cancelHandle(){
        this.closeHandle();
      },
      confirmHandle(){
        this.$refs.moneyaudit.sendReason();
      },
      sendReason(params){
        this.$emit('confirm', params);
      }
    }
  }
</script>

<style scoped>

</style>
